import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';

import ConnectWallet from "./ConnectWallet";
import { LOGO } from '../store';

export default function Menu({setView}) {


  return (
    <Navbar className="p-4" expand="lg">
        <Container>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse className="me-5" id="navbarScroll">
                <Navbar.Brand className="p-3" href="/">
                <Image
                    className="logo"
                    src={LOGO}
                    width="300"
                    height="75"
                />
                </Navbar.Brand>
                <Nav
                    activeKey="/"
                    onSelect={(selectedKey) => {setView(selectedKey)}}
                    navbarScroll
                >
                    <Nav.Link className="p-3" eventKey="home"></Nav.Link>
                    <Nav.Link className="p-3" eventKey="auction">auction</Nav.Link>
                    <Nav.Link className="p-3" eventKey="galery">galery</Nav.Link>
                    <Nav.Link className="p-3" eventKey="market">market</Nav.Link>
                    
                </Nav>
            </Navbar.Collapse>

            <div className='wallet'><ConnectWallet/></div>
                
        </Container>
    </Navbar>
  );

}