import React, { useEffect, useState } from 'react';
import { useAccount, useContractWrite, useContractRead, usePrepareContractWrite } from 'wagmi';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import Countdown from 'react-countdown';
import contractABI from '../contract/current_abi.json';
import axios from 'axios';
import { ethers } from 'ethers';

import ButtonType from '../components/Button';
import Message from '../components/Message';

import { CONTRACT_ADDRESS } from '../store';

const Completionist = () => <span className="fs-2">Ended !</span>;



const convert = (ipfs) => {
  return "https://nftstorage.link/ipfs/" + ipfs.split('://')[1];
}

export default function Auction(){

  const [metadata, setMetadata] = useState({});
  const [currentEndTimes, setCurrentEndTimes] = useState(0);
  const [currentHighestBids, setCurrentHighestBids] = useState(0);
  const [image, setImage] = useState("");
  const [bid, setBid] = useState("0.1");
  const {address, isConnected} = useAccount();
  const [mintLoading, setMintLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEnded, setIsEnded] = useState(false);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsEnded(true);
      return <Completionist />;
    } else {
      return <span className="fs-2">{days}:{hours}:{minutes}:{seconds}</span>;
    }
  };


  const { refetch : currentUri } = useContractRead({
    address: CONTRACT_ADDRESS,
    abi: contractABI,
    functionName: 'currentUri',
    onSuccess(data){
      axios.get(convert(data)).then(res => {
        setMetadata(res.data);
        const urlImage = convert(res.data.image);
        setImage(urlImage);
        setIsLoading(false);
      });
    }
  });

  const { refetch : getCurrentEndTimes } = useContractRead({
    address: CONTRACT_ADDRESS,
    abi: contractABI,
    functionName: 'getCurrentEndTimes',
    onSuccess(data){
      setCurrentEndTimes(parseInt(data)*1000);
      setIsEnded((parseInt(data)*1000 - Date.now())<0);
    }
  });

  const { refetch : getCurrentHighestBids } = useContractRead({
    address: CONTRACT_ADDRESS,
    abi: contractABI,
    functionName: 'getCurrentHighestBids',
    onSuccess(data){
      setCurrentHighestBids(ethers.utils.formatEther(data));
      
    }
  });

  const { config } = usePrepareContractWrite({
    address: CONTRACT_ADDRESS,
    abi: contractABI,
    functionName: 'placeBid',
    overrides: {
      from: address,
      value: ethers.utils.parseEther(bid.toString()),
    },
  });

  const { write } = useContractWrite({
    ...config,
    onSuccess() {
      setTimeout(() => {
        getCurrentHighestBids();
        setMintLoading(false);
      }, 5000);
    },
    onError() {
      setMintLoading(false);
    }
  });

  useEffect(()=>{
    currentUri();
    getCurrentEndTimes();
    getCurrentHighestBids();
  },[]);


  return(
    (isLoading) ? <Message content="Loading ..."/> :
    <>
        <div className="left">

            <Countdown className="fs-2" date={currentEndTimes} renderer={renderer}>
                <Completionist />
            </Countdown>
    
            <div className="fs-6">{metadata.name}</div>
            <div className="fs-4">{metadata.description}</div>
            {metadata.attributes.map((attribute) => <div key={attribute.trait_type} className="fs-7">{attribute.value}</div>)}

        </div>

        <div className="page">

          <Image className="image" src={image}/>

        </div>

        <div className="right">
        
            <Form.Group className="p-2">

              <InputGroup>
                <InputGroup.Text className="rounded-0 bg-black text-white">current highest bid</InputGroup.Text>
                <Form.Control
                  type="text"
                  value = {currentHighestBids}
                  className="rounded-0 bg-black text-white"
                  disabled={true}
                />
              </InputGroup>
            
              <InputGroup>
                <InputGroup.Text className="rounded-0 bg-black text-white">place a bid</InputGroup.Text>
                <Form.Control
                  type="text"
                  value = {bid}
                  onChange={( e ) => { e.preventDefault(); (e.target.value ? setBid(e.target.value): setBid(currentHighestBids)); }}
                  className="rounded-0 bg-black text-white"
                />
              </InputGroup>

            </Form.Group>

            <Form.Group className="p-2">
              <Form.Select className="rounded-0 bg-black text-white">
                <option value="USDC">MATIC</option>
              </Form.Select>
            </Form.Group>

            { (!isConnected) ? <></>:
                <ButtonType
                    content="submit"
                    disabled={mintLoading || isEnded}
                    onClick={() => { setMintLoading(true); write(); }}
                />
            }

        </div>

    </>
  
  )
}