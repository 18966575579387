import Button from 'react-bootstrap/Button';

export default function ButtonType(props){
    return (
        <Button
            {...props}
            className="text-center fs-4 m-5 border-0 rounded-0 bg-black text-white"
        >
        {props.content}
        </Button>
    );
}