import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

const image1 = require(`../assets/concept-1.png`);
const image2 = require(`../assets/concept-2.png`);
const image3 = require(`../assets/concept-3.png`);
const image4 = require(`../assets/concept-4.png`);

export default function Concept() {
    return (
        <Container>
        <h4 className='my-3'>OVERVIEW</h4>
        <div>
        The Stable Art Foundation (SAF) is a 28k supply NFT collection allowing its users to collect & trade the most famous paintings of our History, through the use of DAI and USDC stablecoins. The project is deployed on the Polygon blockchain. The main mission of the SAF is to guarantee its users a stability in terms of valuation and transaction of its NFTs via a very particular and innovative process: the “parity valuation”.
        </div>
        <div className='text-center'><Image className="image-concept" src={image1}/></div>
        <div>
        But what is parity valuation? It is a process that ensures the parity of valuation of a physical item with its tokenized double, so more precisely its NFT version. Indeed, in the case of the Stable Art Foundation, a tokenized version of the physical works of the most iconic and recognized painters of our History are put in circulation on the blockchain. In short: 1 historical physical painting = 1 unique NFT version.
        </div>
        <div className='text-center'><Image className="image-concept" src={image2}/></div>
        <div>
        This means that each known historical painting holds its tokenized double; no other copy being proposed on the blockchain. With this process of double tokenization, the idea is to be able to link the valuation of a physical work to its digital double in order to guarantee in time a parity, then a stability in terms of valuation. For example, if a physical Picasso painting has a valuation of $1M, the ultimate goal would be that its tokenized double from the Stable Art Foundation would naturally reach this same valuation over time. The primary objective of this whole process remains to guarantee stability and security in terms of NFT valuation: we want to make sure that an investment in a SAF NFT is stable and has the least possible exposure to volatility. Each NFT of the SAF is backed directly on its physical double, which guarantees stability in terms of value and rarity of the NFT.
        </div>
        <div className='text-center'><Image className="image-concept" src={image3}/></div>
        <div>
        SAF is not just a simple NFT collection, since it actually offers its own Marketplace through which users can trade their NFT paintings in stablecoins. Yes, the particularity of this marketplace is that ONLY stablecoins can be used to buy or sell the tokenized arts of the collection. However, users will be able to use MATIC or alternative crypto on the secondary marketplaces as they wish. Why are only DAI & USDC stablecoins allowed on the main marketplace? Simply because this is the whole idea behind the project: to guarantee a safe, stable investment alternative for all users. This will be as true for the valuation of SAF NFTs as it is for the transactions on the main marketplace. The Stable Art Foundation wants its users to have peace of mind when investing in its tokenized arts. It wants to gain real confidence from its users by offering an NFT investment alternative that is as solid as investing in precious metals or real estate. And all this will be possible thanks to tokenized art!
        </div>
        <h4 className='my-3'>NO OWNERSHIP: A COMMUNITY-DRIVEN PROJECT</h4>
        <div>
        Since art is something universal that belongs to everyone, it is obvious that we had to give up the ownership of the project. Therefore, the Stable Art Foundation will only be owned by the community. The developers will remain in the project as representatives of the project, but a process of community self-governance will determine the future direction of the project. For their hard work, the developers will receive a 1% royalty on all transactions in the primary and secondary Marketplace, as well as 5% on Mint Auctions.
        </div>
        <div className='text-center'><Image className="image-concept" src={image4}/></div>
        <div>
        Despite the fact that SAF adopts a community-driven model, it is important to remember that the project and especially its marketplace will be totally self-sustaining over time. The whole process will work automatically, with as little human intervention as possible. Thus, the project will be sustainable over time, regardless of events, even internal or external disasters. Measures will be taken to ensure that the project will endure over time and remain unaffected by bad weather. 
        </div>
        <h4 className='my-3'>MINT AUCTION</h4>
        <div>
        The Mint Auctions system is relatively new in the the NFT space, since it involves selling each NFT mint of a collection in the form of an auction, and this, in a progressive way over time.
        </div>
        <h5 className='my-3'>Problem</h5>
        <div>
        In 90% of NFT projects, the same formula is always proposed: a presale phase during which all interested users are invited to buy the NFT mint of a collection at their own expense. What is the biggest problem with this method? Obviously, the biggest risk is the artificial inflation of the value of these NFTs, and consequently the overvaluation of the prices of the NFTs from the start of the project (often caused by an artificial hype around the project in question). 
        </div>
        <Image/>
        <div>
        This situation is really dangerous for any NFT project as it often leads to its premature death. Generally, only the most solid and serious projects are able to survive this ordeal, and last more than a week after a presale. The less solid collections, however, quickly collapse, as they were unable to stabilize prices once the artificial hype had passed. The Mint Auction feature is exactly what is needed to avoid this scenario, or even better: to stimulate a constant activity over time in term of transaction frequency.
        </div>
        <h5 className='my-3'>Solution</h5>
        <div>
        Mint Auctions allow users to bid on an NFT from the SAF collection, which can then be purchased by a member of the community. A Mint Auction lasts 6 hours and rewards the highest bidder at the end. Therefore, each day, 4 Mint Auctions are activated and allow the purchase of mint of digital artworks from the SAF collection. As you will have understood, the Mint Auction will not allow a quick sale of a collection in a few minutes, as the market has accustomed us in recent years, but a progressive sale and distribution of NFTs that will last for YEARS (and this is the goal). The mint will theoretically never be finished since the total supply of the SAF collection amounts to 28k NFTs. 
        </div>
        <Image/>
        <div>
        Contrary to a classic auction as we are used to see on OpenSea for example, a Mint Auction does not sell an already minted NFT, but rather offers the possibility to mint this NFT. So, the highest bidder in the auction will buy his mint, and will therefore have the possibility to mint the NFT he paid for in the auction. The Mint Auction system takes exactly the same format as OpenSea auctions. The only two differences are that bids can only be made in DAI and USDC and that the price margin between each bid must be at least 10% higher than the previous bid.
        </div>
        <Image/>
        <h4 className='my-3'>SHARE SYSTEM</h4>
        <div>
        It's simple: the more SAF NFTs a wallet owns, the more passive income it receives. Indeed, the Share System is simply a process of profit sharing between the holders of SAF NFTs; 20% of the profits on each Mint Auction are thus distributed between the holders. In addition to this, a 3% is taken on all transactions made on the secondary marketplace, then of course shared between all holders. 
        </div>
        <Image/>
        <div>
        Therefore, you will understand that it is very advantageous to hold arts from the SAF collection. The project's main mission is to prioritize the stability and financial viability of its project, and is convinced that the Share System is one of the features that will ensure  this goal over time.   
        </div>
        </Container>
    );
}