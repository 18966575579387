import Asset from '../components/Asset';

const randomInt = () => {
  return Math.floor(Math.random() * 1110) + 1;;
}

const randonInt1 = randomInt();
const image1 = require(`../assets/art/${randonInt1}.jpg`);
const attribut1 = require(`../assets/json/${randonInt1}.json`);
const randonInt2 = randomInt();
const image2 = require(`../assets/art/${randonInt2}.jpg`);
const attribut2 = require(`../assets/json/${randonInt2}.json`);
const randonInt3 = randomInt();
const image3 = require(`../assets/art/${randonInt3}.jpg`);
const attribut3 = require(`../assets/json/${randonInt3}.json`);
const randonInt4 = randomInt();
const image4 = require(`../assets/art/${randonInt4}.jpg`);
const attribut4 = require(`../assets/json/${randonInt4}.json`);
const randonInt5 = randomInt();
const image5 = require(`../assets/art/${randonInt5}.jpg`);
const attribut5 = require(`../assets/json/${randonInt5}.json`);
const randonInt6 = randomInt();
const image6 = require(`../assets/art/${randonInt6}.jpg`);
const attribut6 = require(`../assets/json/${randonInt6}.json`);
const randonInt7 = randomInt();
const image7 = require(`../assets/art/${randonInt7}.jpg`);
const attribut7 = require(`../assets/json/${randonInt7}.json`);
const randonInt8 = randomInt();
const image8 = require(`../assets/art/${randonInt8}.jpg`);
const attribut8 = require(`../assets/json/${randonInt8}.json`);
const randonInt9 = randomInt();
const image9 = require(`../assets/art/${randonInt9}.jpg`);
const attribut9 = require(`../assets/json/${randonInt9}.json`);


export default function Market(props){

  return (

    <div className="market">

        <Asset image={image1} metadata={attribut1}/>
        <Asset image={image2} metadata={attribut2}/>
        <Asset image={image3} metadata={attribut3}/>
 
        <Asset image={image4} metadata={attribut4}/>
        <Asset image={image5} metadata={attribut5}/>
        <Asset image={image6} metadata={attribut6}/>

        <Asset image={image7} metadata={attribut7}/>
        <Asset image={image8} metadata={attribut8}/>
        <Asset image={image9} metadata={attribut9}/>
  
    </div>

  );

}
