import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { BigNumber } from 'ethers';
import { useAccount, useContractWrite, useContractRead, usePrepareContractWrite, useContract } from 'wagmi';
import { useContractInfiniteReads, paginatedIndexesConfig } from 'wagmi'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import contractABI from '../contract/current_abi.json';
import axios from 'axios';

import Message from '../components/Message';
import ButtonType from '../components/Button';

import { CONTRACT_ADDRESS } from '../store';

const contractConfig = {
    address: CONTRACT_ADDRESS,
    abi: contractABI,
}

const getStorageFromIpfs = (ipfs) => {
    return "https://nftstorage.link/ipfs/" + ipfs.split('://')[1];
}


let tokenIds= [];
let tokenURIs= [];


export default function Galery(){

    const [assets, setAssets] = useState([]);
    const [balanceOf, setBalanceOf] = useState();
    const [image, setImage] = useState("");
    const [price, setPrice] = useState();
    const {address, isConnected} = useAccount();
    const [mintLoading, setMintLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useContractRead({
        ...contractConfig,
        functionName: 'balanceOf',
        args: [address],
        onSuccess(data){
            setBalanceOf(parseInt(data));
            
        }
    });

    const { refetch : tokens } = useContractInfiniteReads({
        ...paginatedIndexesConfig(
            (index) => {
                return [
                {
                    ...contractConfig,
                    functionName: 'tokenOfOwnerByIndex',
                    args: [address, index],
                },
                ]
            },
            { start: 0, perPage: balanceOf, direction: 'increment' },
        ),
    });

    const { refetch : getTokenURIs, isSuccess } = useContractInfiniteReads({
        ...paginatedIndexesConfig(
            (index) => {
                const token = tokenIds[index];
                return [
                {
                    ...contractConfig,
                    functionName: 'tokenURI',
                    args: [token],
                },
                ]
            },
            { start: 0, perPage: balanceOf, direction: 'increment' },
        ),
    });

    const getTokens = async () => {
        const responseTokens = await tokens();
        tokenIds = responseTokens.data.pages[0].map(token => parseInt(token));
        const responseURI = await getTokenURIs();
        tokenURIs = responseURI.data.pages[0].map(tokenURI => getStorageFromIpfs(tokenURI));
        const response = await Promise.all(
            tokenURIs.map(tokenURI => axios.get(tokenURI).then(res => res.data)),
        );
        setAssets(response);
        setIsLoading(false);
    }

    useEffect(()=> {
        if(balanceOf){
            getTokens();
        }
    },[balanceOf]);


    return(

        (!isConnected) ? <Message content="Wallet not connected !"/>:
        (isLoading) ? <Message content="Loading ..."/> :
        <Carousel fade indicators={false}>
            {assets.map((metadata) => 
                <Carousel.Item key={metadata.name}>
                    <div className="left">

                        <div className="fs-6">{metadata.name}</div>
                        <div className="fs-4">{metadata.description}</div>
                        {metadata.attributes.map((attribute) => <div key={attribute.trait_type} className="fs-7">{attribute.value}</div>)}

                    </div>

                    <div className="page">
      
                        <Image className="image" key={metadata.name} src={getStorageFromIpfs(metadata.image)}/>

                    </div>

                    <div className="right">
                    
                        <Form.Group className="p-2">
                            <InputGroup>
                                <InputGroup.Text className="rounded-0 bg-black text-white">price</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value = {price}
                                    onChange={( e ) => { e.preventDefault(); setPrice(e.target.value); }}
                                    className="rounded-0 bg-black text-white"
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="p-2">
                            <Form.Select className="rounded-0 bg-black text-white">
                            <option value="USDC">USDC</option>
                            <option value="DAI">DAI</option>
                            </Form.Select>
                        </Form.Group>


                        <ButtonType
                            content="sell"
                        />
     
                    </div>
                </Carousel.Item>
            )}
        </Carousel>
        

    );
}
