import { useState } from 'react';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';


import Menu from './components/Menu';
import Home from './views/Home';
import Auction from './views/Auction';
import Footer from './components/Footer';
import Market from './views/Market';
import Faq from './views/Faq';
import Concept from './views/Concept';
import Galery from './views/Galery';


const { chains, provider } = configureChains(
  [chain.polygonMumbai],
  [
    alchemyProvider({ apiKey: "7UGI-7xN16IUI72DcoNBwjpk2_YbjUQu" }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Stable Art Foundation',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

export default function App(){

  const [view, setView] = useState('home');

  return(

    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} modalSize="compact" theme={lightTheme({ borderRadius: 'none',})}>

          <Menu setView={setView}/>

          { (view === 'home') ? <Home/> : <></>}
          { (view === 'auction') ? <Auction/> : <></>}
          { (view === 'market') ? <Market/> : <></>}
          { (view === 'faq') ? <Faq/> : <></>}
          { (view === 'concept') ? <Concept/> : <></>}
          { (view === 'galery') ? <Galery/> : <></>}

          <Footer setView={setView}/>

      </RainbowKitProvider>
    </WagmiConfig>

  )
}