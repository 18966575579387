import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

export default function Asset(props){

  return ( 
    <div class="asset">
        <Image className="img" src={props.image}/>
        <div class="infos-hover">
          <div className="fs-6">{props.metadata.name}</div>
          <div className="fs-4">{props.metadata.description}</div>
          <div className="fs-8">Holder 0x55...094C</div>
          <Button
                  className="bg-transparent text-center fs-4 m-5 border-0 rounded-0 bg-black text-white"
                >Buy 500 USDC</Button>
        </div>
    </div>
  );

}