import { TagCloud } from 'react-tagcloud';
import alertify from 'alertifyjs';


const data = [
  { 
    value: 'What is Apthypication ?',
    count: 38,
    props: {response : "It’s 5000 unique Apthypic NFT who gonna be out (+ 5000 airdrop for holders)."}
  },
  { 
    value: 'How many NFT will be available ?',
    count: 30,
    props: {response : "One per wallet."}
  },
  {
    value: 'The 5000 other will be charged ?',
    count: 28,
    props: {response : "NO, they gonna be free for those who have NFT from the first 5000."} 
  
  },
  {
    value: 'How can I mint an Apthypication NFT ?',
    count: 25,
    props: {response : "NO, they gonna be free for those who have NFT from the first 5000."}
  },
  {
    value: 'How many Apthypication NFT can I mint ?',
    count: 33,
    props: {response : "NO, they gonna be free for those who have NFT from the first 5000."}
   },
  {
    value: 'What are the reward of staking ?',
    count: 18,
    props: {response : "NO, they gonna be free for those who have NFT from the first 5000."}
  },
  {
    value: 'Can i put my Apthypication NFT in sell during staking ?',
    count: 20,
    props: {response : "NO, they gonna be free for those who have NFT from the first 5000."}
  },
]


export default function Faq() {

  return (

      <div className="page text-white">

        <div className='tagcloud'>

          <TagCloud
              disableRandomColor={true}
              minSize={15}
              maxSize={35}
              tags={data}
              onClick={tag => alertify.alert(`${tag.value}`, `${tag.props.response}`).set({transition:'zoom', 'closable': false})}
            />

        </div>

      </div>

  );

}