import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


export default function Footer({setView}) {

  return (
    <Navbar
      bg="transparent"
      className="footer justify-content-center"
      onSelect={(selectedKey) => {setView(selectedKey)}}
    >

        <Nav.Link className='m-5' eventKey="concept">

            when the crypto is shaky ,  art provides stability - read concept

        </Nav.Link>

    </Navbar>
  );

}