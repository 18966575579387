import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useCursor, MeshReflectorMaterial, Image, Text, Environment } from '@react-three/drei';
import { useRoute, useLocation } from 'wouter';
import { easing } from 'maath';

const GOLDENRATIO = 1.61803398875;

const randomInt = () => {
  return Math.floor(Math.random() * 1110) + 1;;
}

const randonInt1 = randomInt();
const image1 = require(`../assets/art/${randonInt1}.jpg`);
const attribut1 = require(`../assets/json/${randonInt1}.json`);
const name1 = attribut1.description.replace(' ', '-');
const randonInt2 = randomInt();
const image2 = require(`../assets/art/${randonInt2}.jpg`);
const attribut2 = require(`../assets/json/${randonInt2}.json`);
const name2 = attribut2.description.replace(' ', '-');
const randonInt3 = randomInt();
const image3 = require(`../assets/art/${randonInt3}.jpg`);
const attribut3 = require(`../assets/json/${randonInt3}.json`);
const name3 = attribut3.description.replace(' ', '-');
const randonInt4 = randomInt();
const image4 = require(`../assets/art/${randonInt4}.jpg`);
const attribut4 = require(`../assets/json/${randonInt4}.json`);
const name4 = attribut4.description.replace(' ', '-');
const randonInt5 = randomInt();
const image5 = require(`../assets/art/${randonInt5}.jpg`);
const attribut5 = require(`../assets/json/${randonInt5}.json`);
const name5 = attribut5.description.replace(' ', '-');
const randonInt6 = randomInt();
const image6 = require(`../assets/art/${randonInt6}.jpg`);
const attribut6 = require(`../assets/json/${randonInt6}.json`);
const name6 = attribut6.description.replace(' ', '-');
const randonInt7 = randomInt();
const image7 = require(`../assets/art/${randonInt7}.jpg`);
const attribut7 = require(`../assets/json/${randonInt7}.json`);
const name7 = attribut7.description.replace(' ', '-');
const randonInt8 = randomInt();
const image8 = require(`../assets/art/${randonInt8}.jpg`);
const attribut8 = require(`../assets/json/${randonInt8}.json`);
const name8 = attribut8.description.replace(' ', '-');
const randonInt9 = randomInt();
const image9 = require(`../assets/art/${randonInt9}.jpg`);
const attribut9 = require(`../assets/json/${randonInt9}.json`);
const name9 = attribut9.description.replace(' ', '-');

const images = [
  // Front
  { position: [0, 0, 1.5], rotation: [0, 0, 0], url: image1, attribut: name1 },
  // Back
  { position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: image2, attribut: name2 },
  { position: [0.8, 0, -0.6], rotation: [0, 0, 0], url: image3, attribut: name3 },
  // Left
  { position: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0], url: image4, attribut: name4 },
  { position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: image5, attribut: name5 },
  { position: [-2, 0, 2.75], rotation: [0, Math.PI / 2.5, 0], url: image6, attribut: name6 },
  // Right
  { position: [1.75, 0, 0.25], rotation: [0, -Math.PI / 2.5, 0], url: image7, attribut: name7 },
  { position: [2.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: image8, attribut: name8 },
  { position: [2, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: image9, attribut: name9 }
]


export default function Home(){


  return(
    <div className="page">
    <Canvas dpr={[1, 1.5]} camera={{ fov: 80, position: [0, 2, 15] }}>
      
      <color attach="background" args={['#191920']} />
      <fog attach="fog" args={['#191920', 0, 15]} />
      <group position={[0, -0.5, 0]}>
        <Frames images={images} />
        <mesh rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[300, 100]}
            resolution={2048}
            mixBlur={1}
            mixStrength={40}
            roughness={1}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#050505"
            metalness={0.5}
          />
        </mesh>
      </group>
      
    </Canvas>
    </div>
  )
}

function Frames({ images}) {
  const q = new THREE.Quaternion();
  const p = new THREE.Vector3();
  const ref = useRef();
  const clicked = useRef();
  const [, params] = useRoute('/item/:id');
  const [, setLocation] = useLocation();
  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true);
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2, 1.25));
      clicked.current.parent.getWorldQuaternion(q);
    } else {
      p.set(0, 0, 5.5);
      q.identity();
    }
  })
  useFrame((state, dt) => {
    easing.damp3(state.camera.position, p, 0.4, dt);
    easing.dampQ(state.camera.quaternion, q, 0.4, dt);
  })
  return (
    <group
      ref={ref}
      onClick={(e) => (setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} />)}
    </group>
  )
}

function Frame({ url, attribut, ...props }) {
  const image = useRef();
  const frame = useRef();
  const [, params] = useRoute('/item/:id');
  const [hovered, hover] = useState(false);
  const [rnd] = useState(() => Math.random());
  const name = attribut;
  const isActive = params?.id === name;
  useCursor(hovered);
  useFrame((state, dt) => {
    image.current.material.zoom = 2 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
    easing.damp3(image.current.scale, [0.85 * (!isActive && hovered ? 0.85 : 1), 0.9 * (!isActive && hovered ? 0.905 : 1), 1], 0.1, dt)
    easing.dampC(frame.current.material.color, hovered ? 'black' : 'white', 0.1, dt)
  });
  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={() => hover(true)}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}>
        <boxGeometry />
        <meshStandardMaterial color="#151515" metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <Image raycast={() => null} ref={image} position={[0, 0, 0.7]} url={url} />
      </mesh>
      <Text maxWidth={0.1} anchorX="left" anchorY="top" position={[0.55, GOLDENRATIO, 0]} fontSize={0.04}>
        {name.split('-').join(' ')}
      </Text>
    </group>
  )
}

